
import Vue from 'vue';
import Konva from 'konva';
import VueKonva from 'vue-konva';
import { debounce } from 'lodash';
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { CreateModule } from '@/store';
import { MixinKonvaSnapDrag } from '../mixin-konva-snap-drag';
import { encode } from '@frsource/base64';

// types
import { AssetAspect } from '@/models/presets/assetAspect';
import { PresetListItem } from '@/models/presets/presetListItem';

Vue.use(VueKonva);
const createModule = getModule(CreateModule);

@Component({
  components: {}
})
export default class CreateStage extends Mixins(MixinKonvaSnapDrag) {
  public currentTemplateUrl: string = '';
  public selectedNode: object | null = null;
  private debouncedResize = debounce(this.resizeStage, 200);
  private svgStringOriginal: string = '';
  private isStageDirtyByMove = false; // flag to check if we can use existing asset. Was asset moved/resized?
  private isStageDirtyByAspect = false; // flag to check if we can use existing asset. Was asset aspect changed?
  private isStageDirtyByPreset = false; // flag to check if we can use existing asset. Was preset applied?
  private selectedAssetAspect: AssetAspect | null = null;

  public primaryImgConfig: Konva.RectConfig = {
    draggable: true,
    name: 'primaryImg'
  };

  public presetImgConfig: Konva.RectConfig = {
    draggable: false,
    listening: false,
    visible: false
  };

  public targetRectConfig: Konva.RectConfig = {
    draggable: false,
    listening: false,
    fill: this.$vuetify.breakpoint.xs ? 'white' : '#d8d8d8',
    shadowBlur: this.$vuetify.breakpoint.xs ? 10 : 0
  };

  public frameRectConfig: Konva.RectConfig = {
    stroke: this.$vuetify.breakpoint.xs
      ? '#f7f7f7BB'
      : 'rgba(255,255,255,0.75)',
    strokeWidth: 0,
    draggable: false,
    listening: false
  };

  public transformerConfig: Konva.TransformerConfig = {
    enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
    rotationSnaps: [0, 90, 180, 270],
    keepRatio: true,
    borderStroke: this.$vuetify.theme.themes.light.primary?.toString(), // nod to future theming support
    anchorStroke: this.$vuetify.theme.themes.light.primary?.toString(),
    anchorCornerRadius: 50,
    anchorSize: this.$vuetify.breakpoint.xs ? 18 : 12
  };

  public get isPrimaryImgDraggable(): boolean {
    if (!this.$vuetify.breakpoint.xs) return true;
    return this.isMobClearBtnVisible;
  }

  public get isStageVisible() {
    if (this.$vuetify.breakpoint.xs && createModule.currentStep >= 4) {
      return false;
    }
    return this.isStageImgLoaded;
  }

  public get isMobClearBtnVisible() {
    return this.$vuetify.breakpoint.xs && createModule.currentStep === 1;
  }

  public get isStageImgLoaded() {
    return createModule.isStageImgLoaded;
  }

  public get assetAspectModel(): AssetAspect {
    return createModule.assetAspect;
  }

  public set assetAspectModel(newVal: AssetAspect) {
    createModule.setAssetAspect(newVal);
  }

  public get AssetAspect() {
    return AssetAspect;
  }

  public get isAspectToggleVisible(): boolean {
    if (!this.$vuetify.breakpoint.xs) {
      return true;
    }

    return createModule.currentStep === 2;
  }

  public get selectedPreset(): PresetListItem {
    return createModule.selectedPreset;
  }

  public get stageImgUrl(): string | null {
    return createModule.stageImgUrl;
  }

  private get konvaStage(): Konva.Stage {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.$refs.konvaStage as any).getNode();
  }

  private get konvaPrimaryImg(): Konva.Image {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.$refs.konvaPrimaryImg as any).getNode();
  }

  private get konvaPresetImg(): Konva.Image {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.$refs.konvaPresetImg as any).getNode();
  }

  private get konvaTargetRect(): Konva.Rect {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.$refs.konvaTargetRect as any).getNode();
  }

  private get konvaFrameRect(): Konva.Rect {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.$refs.konvaFrameRect as any).getNode();
  }

  private get konvaTransformer(): Konva.Transformer {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.$refs.konvaTransformer as any).getNode();
  }

  public get isStageDirty(): boolean {
    return (
      this.isStageDirtyByMove ||
      this.isStageDirtyByAspect ||
      this.isStageDirtyByPreset
    );
  }

  public reset() {
    this.konvaPrimaryImg.scale({ x: 1, y: 1 });
    this.konvaPrimaryImg.rotation(0);
    this.konvaPresetImg.hide();
    this.isStageDirtyByMove = false;
    this.isStageDirtyByAspect = false;
    this.isStageDirtyByPreset = false;

    createModule.reset();
  }

  public onStageContainerFocusOut() {
    this.selectedNode = null;
  }

  public setPrimaryImageDimensions() {
    const targetWidth = this.konvaTargetRect.width();
    const imageWidth = this.konvaPrimaryImg.width();
    const imageHeight = this.konvaPrimaryImg.height();
    const widthFit: number = targetWidth / imageWidth;
    const newWidth = imageWidth * widthFit;
    const newHeight = imageHeight * widthFit;
    this.konvaPrimaryImg.width(newWidth);
    this.konvaPrimaryImg.height(newHeight);
  }

  public resizeStage() {
    const stageContainer = this.$refs.stageContainer as HTMLElement;
    const containerWidth = stageContainer.offsetWidth - 2; // 2 border is 1px
    const containerHeight = stageContainer.offsetHeight - 2;

    this.konvaStage.width(containerWidth);
    this.konvaStage.height(containerHeight);

    const strokeMod = this.$vuetify.breakpoint.xs ? 20 : 56;

    // target rectangle
    this.konvaTargetRect.width(containerWidth - strokeMod * 2);
    this.konvaTargetRect.height(containerHeight - strokeMod * 2);
    this.konvaTargetRect.x(strokeMod);
    this.konvaTargetRect.y(strokeMod);

    // frame rectangle
    this.konvaFrameRect.width(containerWidth);
    this.konvaFrameRect.height(containerHeight);
    this.konvaFrameRect.strokeWidth(strokeMod * 2);

    this.konvaStage.draw();
  }

  public setToCenter(image: Konva.Image) {
    image.x((this.konvaStage.width() - image.getWidth()) / 2);
    image.y((this.konvaStage.height() - image.getHeight()) / 2);
  }

  public onStageMouseDown(e: any) {
    // clicked on stage - clear selection
    if (e.target === this.konvaStage) {
      this.selectedNode = null;
      return;
    }

    // clicked on transformer - do nothing
    if (e.target.getParent().getClassName() === 'Transformer') {
      return;
    }

    if (this.isMobClearBtnVisible) return;

    this.selectedNode = e.target;
  }

  mounted() {
    window.addEventListener('resize', this.debouncedResize);
    (this.$refs.stageContainer as HTMLElement).addEventListener(
      'keydown',
      this.onStageContainerKeyDown
    );

    this.konvaPrimaryImg.on('mouseenter', () => {
      this.konvaStage.container().style.cursor = 'move';
    });

    this.konvaPrimaryImg.on('mouseleave', () => {
      this.konvaStage.container().style.cursor = 'default';
    });

    this.$root.$on('get-stage-thumbnail', this.getStageThumbnail);
    this.$root.$on('update-svg-labels', this.updatePresetSvg);
    this.$root.$on(
      'stage-is-dirty-by-move',
      () => (this.isStageDirtyByMove = true)
    );
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.debouncedResize);
    (this.$refs.stageContainer as HTMLElement).removeEventListener(
      'keydown',
      this.onStageContainerKeyDown
    );
    this.konvaPrimaryImg.off('mouseleave mouseenter');

    this.$root.$off('get-stage-thumbnail');
    this.$root.$off('update-svg-labels');
    this.$root.$off('stage-is-dirty-by-move');
  }

  public onStageContainerKeyDown(e: KeyboardEvent) {
    e.preventDefault();
    if (e.key === 'Delete' || e.key === 'Backspace') {
      // delete primaryImg
      if (
        (this.selectedNode as Konva.Image)?.hasName(this.primaryImgConfig.name)
      ) {
        this.reset();
      }
    }

    // konvaPrimaryImg keyboard position manipulation
    if (!this.isStageImgLoaded) return;
    const delta = 2 + (e.shiftKey ? 10 : 0);
    switch (e.key) {
      case 'ArrowUp':
        this.konvaPrimaryImg.y(this.konvaPrimaryImg.y() - delta);
        break;
      case 'ArrowRight':
        this.konvaPrimaryImg.x(this.konvaPrimaryImg.x() + delta);
        break;
      case 'ArrowDown':
        this.konvaPrimaryImg.y(this.konvaPrimaryImg.y() + delta);
        break;
      case 'ArrowLeft':
        this.konvaPrimaryImg.x(this.konvaPrimaryImg.x() - delta);
        break;
    }
  }

  @Watch('stageImgUrl')
  public loadStageImg() {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      createModule.setIsStageImgLoaded(true);
      this.konvaPrimaryImg.image(img);
      this.konvaPrimaryImg.width(img.naturalWidth);
      this.konvaPrimaryImg.height(img.naturalHeight);

      // set aspect
      const isSquare = img.naturalWidth === img.naturalHeight;
      this.selectedAssetAspect = isSquare
        ? AssetAspect.Square
        : AssetAspect.Portrait;
      createModule.setAssetAspect(
        isSquare ? AssetAspect.Square : AssetAspect.Portrait
      );

      this.$nextTick(() => {
        this.resizeStage();
        this.setPrimaryImageDimensions();
        this.setToCenter(this.konvaPrimaryImg);
        this.selectedNode = this.konvaPrimaryImg;
        (this.$refs.stageContainer as HTMLElement).focus(); // help facilitate @focusout

        this.MixinKonvaSnapDrag(
          this.konvaStage,
          this.konvaPrimaryImg,
          this.konvaTargetRect,
          this.konvaTransformer
        );
      });
    };
    img.src = `${this.stageImgUrl}` || '';
  }

  @Watch('assetAspectModel')
  private onAspectChange(newVal: AssetAspect) {
    if (this.selectedAssetAspect) {
      this.isStageDirtyByAspect = newVal !== this.selectedAssetAspect;
    }

    // wait for dom update
    this.$nextTick(() => {
      this.resizeStage();

      // update current preset
      if (createModule.selectedPreset.id !== 'none') {
        this.updatePresetSvg();
      }
    });
  }

  @Watch('selectedPreset') // removed deep watch to more finely control when labels trigger updatePresetSvg()
  private onPresetUpdate() {
    if (createModule.selectedPreset.id === 'none') {
      this.konvaPresetImg.hide();
      this.isStageDirtyByPreset = false;
      return;
    }
    this.updatePresetSvg();
    this.konvaPresetImg.show();
    this.isStageDirtyByPreset = true;
  }

  // called on aspect toggle (1)
  // called on preset change (2)
  // called on preset nested label changes via $root event bus @created() (3)
  private async updatePresetSvg() {
    // same svg and aspect?
    const templateFileKey = `${this.assetAspectModel}TemplateFileUrl`;
    const newTemplateFileUrl: any =
      createModule.selectedPreset[templateFileKey as keyof PresetListItem] ||
      ''; // TODO: fix type

    // (1) assetAspectModel has changed so we need new svg string
    // (2) a new preset was selected so we need new svg string
    if (newTemplateFileUrl !== this.currentTemplateUrl) {
      createModule.setIsPresetLoading(true);
      this.svgStringOriginal = await fetch(newTemplateFileUrl).then(response =>
        response.text()
      );
      this.currentTemplateUrl = newTemplateFileUrl;
    }

    // convert svg string to dom element to assist with label changes
    const parser = new DOMParser();
    const svgDom = parser.parseFromString(
      this.svgStringOriginal,
      'image/svg+xml'
    );

    // (3) preset labels have changed
    this.selectedPreset.presetLabels?.forEach((label, i) => {
      const targetNode = svgDom.getElementById(`label-${i}`);
      if (targetNode) targetNode.innerHTML = label.currentText!;
    });

    // encode svg node to svg base64
    const svgStr = svgDom.documentElement.outerHTML;
    const encodedSvg = 'data:image/svg+xml;base64,' + encode(svgStr);

    const img = new Image();
    img.onload = () => {
      this.konvaPresetImg.image(img);
      this.konvaPresetImg.width(this.konvaTargetRect.width());
      this.konvaPresetImg.height(this.konvaTargetRect.height());
      this.konvaPresetImg.x(this.konvaTargetRect.x());
      this.konvaPresetImg.y(this.konvaTargetRect.y());
      this.konvaStage.draw();
      createModule.setIsPresetLoading(false);
    };
    img.src = encodedSvg;
  }

  // called by parent @submitAsset()
  // called by getStageThumbnail()
  public exportCanvasToDataUrl(pixelRatio: number | undefined): string {
    this.konvaTransformer.detach();

    // We want a 1200px image, so need to calculate the pixel ratio that will result in an image that size.
    if (!pixelRatio) pixelRatio = 1200 / this.konvaFrameRect.width();

    // This will convert a section of the stage to a base64 encoded string:
    return this.konvaStage.getChildren()[0].toDataURL({
      mimeType: 'image/jpeg',
      quality: 0.85,
      x: this.konvaTargetRect.x(),
      y: this.konvaTargetRect.y(),
      width: this.konvaTargetRect.width(),
      height: this.konvaTargetRect.height(),
      pixelRatio
    });
  }

  // createPostInfo @mob requests thumbnail
  private getStageThumbnail() {
    this.$root.$emit('set-stage-thumbnail', this.exportCanvasToDataUrl(0.5));
  }

  @Watch('selectedNode')
  private onSelectedNodeChange(node: Konva.Node | null) {
    const nodes: Konva.Node[] = [];
    if (node) {
      nodes.push(node);
    }
    this.konvaTransformer.nodes(nodes);
  }

  @Watch('isStageDirty')
  private onIsStageDirtyChange(newVal: boolean) {
    this.createModule.setIsSelectedAssetStageDirty(newVal);
  }

  @Watch('isMobClearBtnVisible')
  onMobClearBtnVisibileChange(flag: boolean) {
    return (this.primaryImgConfig.draggable = !flag);
  }
}
