
// TODO: watch $vuetiy.breakpoint change so we can completely reset create-asset & create-post due to flow differences. may be able to store current state. cost/benefit tho?
import { Component, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { getModule } from 'vuex-module-decorators';
import { CreateModule, CreateAssetModule, CreatePostModule } from '@/store';
import { AssetsServiceClient } from '@/api/clients/assetsServiceClient';
import { PostsServiceClient } from '@/api/clients/postsServiceClient';

// route components
import CreateStepperHeader from '../components/createStepperHeader.vue';
import CreateNavigation from '../components/createNavigation.vue';
import CreateNavigationMob from '../components/createNavigationMob.vue';
import CreateAssetImport from '../components/createAssetImport.vue';
import CreateAssetImportMob from '../components/createAssetImportMob.vue';
import CreateAssetTitle from '../components/createAssetTitle.vue';
import CreateStage from '../components/createStage.vue';
import CreateVideo from '../components/createVideo.vue';
import CreateSubmitFeedback from '../components/createSubmitFeedback.vue';
import AssetLibraryIndex from '@/modules/assetLibrary/views/index.vue';

// step 1
import CreatePresetCategories from '../components/createPresetCategories.vue';
import CreatePresetThumbnails from '../components/createPresetThumbnails.vue';
import CreatePresetLabels from '../components/createPresetLabels.vue';

// step 2
import CreatePostInfo from '../components/createPostInfo.vue';
import CreateAssetInfo from '../components/createAssetInfo.vue';

// step 3
import CreatePostSchedule from '../components/createPostSchedule.vue';

// types
import { AssetListItem } from '@/models/assets/assetListItem';
import {
  CreateSubmitTypes,
  CreateSubmitStatus,
  PostSubmitFeedbackMap,
  AssetSubmitFeedbackMap,
} from '@/models/createSubmitTypes';
import { AssetEditingMode } from '@/models/createAssetEditingModes';
import { SocialChannel } from '@/models/posts/socialChannels';
import { DraftPost } from '@/models/posts/draftPost';
import { PublishPostRequest } from '@/api/contracts/posts/publishPostRequest';

interface CreateStageInterface extends Vue {
  exportCanvasToDataUrl(): string;
}
interface SubmitPromiseErrorResults {
  fulfilled: PromiseFulfilledResult<any>[];
  rejected: PromiseRejectedResult[];
}

export interface SelectedStoresWithChannels {
  storeTitle: string;
  channels: SocialChannel[];
}

export interface SelectedStoresWithChannelsMap {
  [storeId: string]: SelectedStoresWithChannels;
}

const createModule = getModule(CreateModule);
const createAssetModule = getModule(CreateAssetModule);
const createPostModule = getModule(CreatePostModule);

const assetServiceClient = new AssetsServiceClient();
const postsServiceClient = new PostsServiceClient();

@Component({
  name: 'CreateIndex',
  components: {
    CreateStepperHeader,
    CreateNavigation,
    CreateNavigationMob,
    CreatePresetCategories,
    CreatePresetThumbnails,
    CreatePresetLabels,
    CreateStage,
    CreateVideo,
    CreateAssetImport,
    CreateAssetImportMob,
    CreatePostInfo,
    CreateAssetInfo,
    CreatePostSchedule,
    CreateSubmitFeedback,
    CreateAssetTitle,
    AssetLibraryIndex,
  },
})
export default class CreateIndex extends LoggedInComponentBase {
  public stepTitle: string = 'Asset and styling';
  public assetTitleErrorFlag: boolean = false;
  public isSubmittingPost: boolean = false;
  public isSubmittingAsset: boolean = false;
  public isAssetSubmitError: boolean = false;
  public isPostSubmitError: boolean = false;
  public AssetEditingModes = AssetEditingMode;
  public assetSubmitFeedback: AssetSubmitFeedbackMap = {};
  public postSubmitFeedback: PostSubmitFeedbackMap = {};

  public get isExistingAsset(): boolean {
    return createModule.selectedAsset !== null;
  }

  public get assetEditingMode(): AssetEditingMode {
    if (!this.isExistingAsset) {
      return AssetEditingMode.New;
    }
    if (this.isVideoAsset) {
      return AssetEditingMode.Video;
    }
    if (this.createModule.isSelectedAssetStageDirty) {
      return AssetEditingMode.Edited;
    }
    return AssetEditingMode.Existing;
  }

  public get isVideoAsset(): boolean {
    return !!createModule.selectedAsset?.isVideoAsset;
  }
  public get isNationalAsset(): boolean {
    return !!createModule.selectedAsset?.isRecommended;
  }
  public get isNonEditableAsset(): boolean {
    return this.isVideoAsset || this.isNationalAsset;
  }
  public get isSubmitting(): boolean {
    return this.isSubmittingAsset || this.isSubmittingPost;
  }
  public get isSubmitFeedbackVisible(): boolean {
    return (
      this.isSubmittingAsset ||
      this.isSubmittingPost ||
      this.isAssetSubmitError ||
      this.isPostSubmitError
    );
  }
  public get isPost(): boolean {
    return this.$route.path.includes('create-post');
  }
  public get currentStep() {
    return createModule.currentStep;
  }
  public get isStageImgLoaded() {
    return createModule.isStageImgLoaded;
  }
  public get isAssetTitleRequired(): boolean {
    return (
      this.assetEditingMode !== AssetEditingMode.Existing &&
      this.assetEditingMode !== AssetEditingMode.Video
    );
  }

  //
  //
  //
  public get isAssetPresetStep(): boolean {
    const step = this.$vuetify.breakpoint.xs ? 1 : 1;
    return !this.isPost && this.isStageImgLoaded && this.currentStep === step;
  }
  public get isAssetUploadStep(): boolean {
    const step = this.$vuetify.breakpoint.xs ? 4 : 2;
    return !this.isPost && this.currentStep === step;
  }
  public get isPostInfoStep(): boolean {
    const step = this.$vuetify.breakpoint.xs ? 4 : 2;
    return this.isPost && this.currentStep === step;
  }
  public get isPostScheduleStep(): boolean {
    const step = this.$vuetify.breakpoint.xs ? 5 : 3;
    return this.isPost && this.currentStep === step;
  }
  public get isLastStepStageIsVisibleOnMob(): boolean {
    return this.$vuetify.breakpoint.xs && this.currentStep === 3;
  }
  //
  //
  //

  public get isNextDisabled(): boolean {
    if (!createModule.isStageImgLoaded && !this.isNonEditableAsset) return true;

    if (
      this.isAssetPresetStep &&
      this.assetEditingMode === AssetEditingMode.Existing
    ) {
      return true;
    }
    if (this.isAssetUploadStep) {
      if (this.isAssetTitleRequired && this.assetTitle === '') return true;
      // mso and no selected stores
      if (
        !this.userModule.isViewingSingleStore &&
        !createAssetModule.msoSelectedStores.length
      )
        return true;
    }
    if (this.isPostInfoStep) {
      if (
        createPostModule.postTitle === '' ||
        createPostModule.postBody === '' ||
        !createPostModule.selectedStoresAndChannels.length
      )
        return true;
    }
    if (this.isPostScheduleStep) {
      const isDateTimeComplete: boolean =
        !!createPostModule.scheduledDate && !!createPostModule.scheduledTime;
      if (!createPostModule.isScheduledImmediately && !isDateTimeComplete)
        return true;
      if (this.isAssetTitleRequired && this.assetTitle === '') return true;
    }

    return false;
  }
  public get nextBtnText(): string {
    if (this.isAssetUploadStep) {
      return 'Upload';
    }
    if (this.isPostScheduleStep) {
      return 'Publish';
    }
    return 'Next';
  }

  public get assetTitle(): string {
    return createAssetModule.assetTitle;
  }

  public get selectedAsset() {
    return createModule.selectedAsset;
  }

  public isStageVisible(): boolean {
    const stageRef = this.$refs.stage as CreateStage;
    return !!stageRef?.isStageVisible;
  }

  private setVideoFlow() {
    this.createModule.setIsAssetLibraryVisible(false);
    const postInfoStep = this.$vuetify.breakpoint.xs ? 4 : 2;
    createModule.setCurrentStep(postInfoStep);
  }

  async mounted() {
    // run this every time so the store switcher is updating
    await createModule.getPresetCategories();

    // selected asset?
    if (this.selectedAsset !== null) {
      if (!this.isVideoAsset) {
        // image
        createModule.setStageImgUrl(this.selectedAsset.blobUrl);
      } else {
        this.setVideoFlow();
      }
      createAssetModule.setAssetTitle(this.selectedAsset!.title);
    }
  }

  beforeDestroy() {
    this.resetStores();
  }

  private resetStores() {
    createModule.reset();
    createPostModule.reset();
    createAssetModule.reset();
  }

  public closeAssetLibrary() {
    this.createModule.setIsAssetLibraryVisible(false);
  }

  public onCancelClick() {
    if (this.isSubmitting) return;
    this.$router.back();
  }

  public onBackClick() {
    if (this.isSubmitting) return;

    if (
      this.isVideoAsset &&
      this.currentStep === 4 &&
      this.$vuetify.breakpoint.xs
    ) {
      createModule.setCurrentStep(1);
      return;
    }

    createModule.setCurrentStep(this.currentStep - 1);
  }

  public async onNextClick() {
    if (this.isLastStepStageIsVisibleOnMob) {
      // store canvas export as exporting when out of viewport causing error
      const stageExport = (
        this.$refs.stage as CreateStageInterface
      ).exportCanvasToDataUrl();
      createModule.setStageExport(stageExport);
    }

    if (this.isAssetUploadStep) {
      await this.submitAsset();

      if (this.isAssetSubmitError) {
        // await user feedback close
        return;
      }

      // redirect to asset library and display feedback
      createModule.setSubmitSuccess(CreateSubmitTypes.ASSET);
      this.$router.push({ name: 'assetlibrary.root' });
      this.resetStores();
      return;
    }

    if (this.isPostScheduleStep) {
      await this.submitPost();

      if (this.isPostSubmitError || this.isAssetSubmitError) {
        // await user feedback close
        return;
      }

      // redirect to home and display feedback
      createModule.setSubmitSuccess(CreateSubmitTypes.POST);
      this.$router.push({ name: 'home.root' });
      this.resetStores();
      return;
    }

    // video asset skip?
    if (this.isVideoAsset && this.currentStep === 1) {
      this.setVideoFlow();
      return;
    }

    createModule.setCurrentStep(this.currentStep + 1);
  }

  public onSubmitFeedbackClose() {
    if (this.isAssetUploadStep) {
      this.$router.push({ name: 'assetlibrary.root' });
      return;
    }
    if (this.isPostScheduleStep) {
      this.$router.push({ name: 'home.root' });
    }
    this.resetStores();
  }

  public onStepHeaderChange(newTitle: string) {
    this.stepTitle = newTitle;
  }

  private get selectedStoresWithChannelsMap(): SelectedStoresWithChannelsMap {
    const selectedStoresWithChannels: SelectedStoresWithChannelsMap = {};
    createPostModule.selectedStoresAndChannels.forEach((store) => {
      const channels: SocialChannel[] = [];
      if (store.facebook.value) channels.push(SocialChannel.Facebook);
      if (store.instagram.value) channels.push(SocialChannel.Instagram);

      selectedStoresWithChannels[store.id] = {
        storeTitle: store.name,
        channels,
      };
    });
    return selectedStoresWithChannels;
  }

  public async submitPost() {
    this.isPostSubmitError = false;
    this.isSubmittingPost = true;

    // submit new asset if needed
    if (
      this.assetEditingMode !== AssetEditingMode.Existing &&
      this.assetEditingMode !== AssetEditingMode.Video
    ) {
      await this.submitAsset();
    }

    console.log('submitting post');

    if (!this.userModule.isViewingSingleStore) {
      // get new msoPostId
      await createPostModule.getMsoPostId();
      console.log('msoPostId', createPostModule.msoPostId);
    }

    const promises = [
      ...Object.entries(this.selectedStoresWithChannelsMap),
    ].map(([storeId, { storeTitle, channels }]) => {
      // feedback pending
      this.$set(this.postSubmitFeedback, storeId, {
        storeTitle,
        channels,
        status: CreateSubmitStatus.PENDING,
      });

      return this.submitNewPost(channels, storeId, createPostModule.msoPostId)
        .then((response: any) => {
          // feedback success
          this.$set(this.postSubmitFeedback, storeId, {
            storeTitle,
            status: CreateSubmitStatus.SUCCESS,
            channels,
          });
        })
        .catch((response) => {
          console.log('catch', response);

          // feedback fail
          this.$set(this.postSubmitFeedback, storeId, {
            storeTitle,
            status: CreateSubmitStatus.FAIL,
            displayError: response?.data?.displayMessage,
            errorHelpLink: response?.data?.errorHelpLink,
            exception: response?.data?.exception,
            channels,
          });

          this.isPostSubmitError = true;
        });
    });

    await Promise.allSettled(promises).then(async (results) => {
      console.log('all settled');
      await new Promise((r) => setTimeout(r, 1000)); // feedback overlay close delay
      this.isSubmittingPost = false;
    });

    console.log('finished submitPost');
  }

  private async submitNewPost(
    selectedChannels: SocialChannel[],
    storeId: guid,
    msoPostId?: guid
  ) {
    const draftPost = this.getDraftPostParams(
      selectedChannels,
      storeId,
      msoPostId
    );

    const createPostResponse = await this.createPostModule.saveDraftPost(
      draftPost
    );

    const params: PublishPostRequest = {
      postId: createPostResponse.data.data.id,
      channels: selectedChannels,
      isDraft: false,
    };

    if (createPostModule.isScheduledImmediately) {
      params.publishTime = new Date().toISOString();
    } else {
      params.publishTime = new Date(
        `${createPostModule.scheduledDate}T${this.fixTimeAm(
          createPostModule.scheduledTime
        )}`
      ).toISOString();
    }

    return await postsServiceClient.publishPost(params, storeId);
  }

  private getDraftPostParams(
    channels: SocialChannel[],
    storeId: guid,
    msoPostId?: guid
  ): DraftPost {
    let assetId: guid;
    if (
      this.assetEditingMode === AssetEditingMode.Existing ||
      this.assetEditingMode === AssetEditingMode.Video
    ) {
      assetId = createModule.selectedAsset!.id;
    } else {
      assetId = createAssetModule.storeAssetIdsMap.get(storeId)!;
    }

    const post: DraftPost = {
      title: createPostModule.postTitle,
      copy: createPostModule.postBody,
      channels: channels,
      storeId: storeId,
      isRecommended: false,
      asset: {
        id: assetId,
      },
      usedTemplateId: createPostModule.usedTemplateId,
    };

    if (msoPostId) post.msoPostId = msoPostId;

    if (createPostModule.isScheduledImmediately) {
      post.suggestedPublishDateTime = new Date().toISOString();
    } else {
      post.suggestedPublishDateTime = new Date(
        `${createPostModule.scheduledDate}T${this.fixTimeAm(
          createPostModule.scheduledTime
        )}`
      ).toISOString();
    }
    return post;
  }

  public fixTimeAm(timeStr: string | null): string {
    if (!timeStr) return '';
    return timeStr.length < 5 ? `0${timeStr}` : timeStr;
  }

  public async submitAsset() {
    console.log('submitting asset');
    this.isAssetSubmitError = false;
    this.isSubmittingAsset = true;
    this.createAssetModule.clearNewlyCreatedAssetIds();

    if (!createModule.stageExport) {
      const stageExport = (
        this.$refs.stage as CreateStageInterface
      ).exportCanvasToDataUrl();
      createModule.setStageExport(stageExport);
    }

    const asset: AssetListItem = {
      id: '',
      title: createAssetModule.assetTitle.trim(),
      blobUrl: '',
      category: '',
      isVideoAsset: false,
      isYoutubeVideo: false,
      videoCode: '',
      campaignCategories: [
        {
          id: createAssetModule.selectedCategory?.id,
          title: createAssetModule.selectedCategory?.title,
        },
      ],
      isRecommended: false,
      imageData: createModule.stageExport,
      fileName: `${createAssetModule.assetTitle
        .trim()
        .replace(/\s/g, '_')}.jpg`,
      availableChannels: createAssetModule.channels,
    };

    let promises: Promise<any>[] = [];

    if (this.userModule.isViewingSingleStore) {
      // single asset

      const storeId = this.userModule.currentStore.id;
      const storeTitle = this.userModule.currentStore.title;

      // feedback pending
      this.$set(this.assetSubmitFeedback, storeId, {
        storeTitle,
        status: CreateSubmitStatus.PENDING,
      });

      promises.push(
        assetServiceClient
          .postAsset({
            storeId,
            asset,
          })
          .then((response: any) => {
            // used when creating draft post
            createAssetModule.addStoreAssetId({
              storeGuid: storeId,
              assetGuid: response.data.id,
            });

            // feedback success
            this.$set(this.assetSubmitFeedback, storeId, {
              storeTitle,
              status: CreateSubmitStatus.SUCCESS,
            });

            this.createAssetModule.storeNewlyCreatedAssetId(response.data.id);
          })
          .catch((response) => {
            console.log('catch', response);

            // feedback fail
            this.$set(this.assetSubmitFeedback, storeId, {
              storeTitle,
              status: CreateSubmitStatus.FAIL,
              displayError: response?.data?.displayMessage,
              errorHelpLink: response?.data?.errorHelpLink,
              exception: response?.data?.exception,
            });

            this.isAssetSubmitError = true;
          })
      );
    } else {
      // get new sharedMsoAssetId
      await createAssetModule.getSharedMsoAssetId();
      console.log('sharedMsoAssetId', createAssetModule.sharedMsoAssetId);

      if (!this.isPost) {
        // create-asset createAssetInfo table

        promises = createAssetModule.msoSelectedStores.map((store, index) => {
          // feedback pending
          this.$set(this.assetSubmitFeedback, store.storeId, {
            storeTitle: store.name,
            status: CreateSubmitStatus.PENDING,
          });

          return assetServiceClient
            .postMsoAsset({
              //@ts-ignore
              asset: asset, // spoof this to test error handling. use: index === 0 ? null : asset
              msoGuid: createAssetModule.sharedMsoAssetId,
              storeId: store.storeId,
            })
            .then((response: any) => {
              // used when creating draft post
              createAssetModule.addStoreAssetId({
                storeGuid: store.storeId,
                assetGuid: response.data.id,
              });

              // feedback success
              this.$set(this.assetSubmitFeedback, store.storeId, {
                storeTitle: store.name,
                status: CreateSubmitStatus.SUCCESS,
              });

              this.createAssetModule.storeNewlyCreatedAssetId(response.data.id);
            })
            .catch((response) => {
              console.log('catch', response);

              // feedback fail
              this.$set(this.assetSubmitFeedback, store.storeId, {
                storeTitle: store.name,
                status: CreateSubmitStatus.FAIL,
                displayError: response?.data?.displayMessage,
                errorHelpLink: response?.data?.errorHelpLink,
                exception: response?.data?.exception,
              });

              this.isAssetSubmitError = true;
            });
        });
      } else {
        // create-post createPostStoreChannelsTable
        // TODO: Refactor. DRY. store.id/store.storeId only difference
        promises = createPostModule.selectedStoresAndChannels.map(
          (store, index) => {
            // feedback pending
            this.$set(this.assetSubmitFeedback, store.id, {
              storeTitle: store.name,
              status: CreateSubmitStatus.PENDING,
            });

            return assetServiceClient
              .postMsoAsset({
                //@ts-ignore
                asset: asset, // spoof this to test error handling. use: index === 0 ? null : asset
                msoGuid: createAssetModule.sharedMsoAssetId,
                storeId: store.id,
              })
              .then((response: any) => {
                // used when creating draft post
                createAssetModule.addStoreAssetId({
                  storeGuid: store.id,
                  assetGuid: response.data.id,
                });

                // feedback success
                this.$set(this.assetSubmitFeedback, store.id, {
                  storeTitle: store.name,
                  status: CreateSubmitStatus.SUCCESS,
                });
              })
              .catch((response) => {
                console.log('catch', response);

                // feedback fail
                this.$set(this.assetSubmitFeedback, store.id, {
                  storeTitle: store.name,
                  status: CreateSubmitStatus.FAIL,
                  displayError: response?.data?.displayMessage,
                  errorHelpLink: response?.data?.errorHelpLink,
                  exception: response?.data?.exception,
                });

                this.isAssetSubmitError = true;
              });
          }
        );
      }
    }

    await Promise.allSettled(promises).then(async (results) => {
      console.log('all settled');
      if (!this.isPost) {
        await new Promise((r) => setTimeout(r, 1000)); // feedback overlay close delay
      }
      this.isSubmittingAsset = false;
    });

    console.log('finished submitAsset');
  }

  @Watch('currentStep')
  private onStepChange() {
    // fix for mobile broken canvas (create-post and create-asset)
    if (this.$vuetify.breakpoint.xs && this.currentStep === 3) {
      const stageRef = this.$refs.stage as CreateStage;
      setTimeout(stageRef.resizeStage, 0);
    }

    if (this.isAssetUploadStep && this.assetTitle === '') {
      this.assetTitleErrorFlag = true;
    }
    if (this.isPostScheduleStep && this.assetTitle === '') {
      this.assetTitleErrorFlag = true;
    }
  }

  // turn off manual error state
  @Watch('assetTitle')
  private onAssetTitleChange() {
    if (this.assetTitleErrorFlag && this.assetTitle !== '') {
      this.assetTitleErrorFlag = false;
    }
  }

  @Watch('selectedAsset')
  private onSelectedAssetChange() {
    if (this.selectedAsset !== null) {
      createModule.setStageImgUrl(this.selectedAsset.blobUrl);
    }
  }

  @Watch('isStageImgLoaded')
  private onIsStageImgLoadedChange(flag: boolean) {
    if (flag) {
      this.createModule.setIsAssetLibraryVisible(false);
      if (this.$vuetify.breakpoint.xs) {
        createModule.setCurrentStep(this.currentStep + 1);
      }
    }
  }

  @Watch('assetEditingMode')
  private setVideoFlowOnAssetEditingModeChange(newMode: AssetEditingMode) {
    if (newMode === AssetEditingMode.Video) this.setVideoFlow();
  }
}
