
import { Component, Prop, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';

@Component({
  components: {},
})
export default class CreateAssetImport extends LoggedInComponentBase {
  @Prop({ default: false }) isPost!: boolean;
  @Prop({ default: 0 }) currentStep!: number;

  public $refs!: Vue['$refs'] & {
    fileInput: HTMLInputElement;
    photoInput: HTMLInputElement;
  };

  public get isStageImgLoaded() {
    return this.createModule.isStageImgLoaded;
  }

  public get isShowing() {
    return (
      !this.createModule.isAssetLibraryVisible &&
      this.createModule.selectedAsset === null &&
      !this.isStageImgLoaded &&
      this.$vuetify.breakpoint.xs
    );
  }

  public onUploadImgBtnClick(): void {
    this.$refs.fileInput.click();
  }

  public onTakePhotoBtnClick(): void {
    this.$refs.photoInput.click();
  }

  public onSelectFromLibraryClick() {
    this.createModule.setIsAssetLibraryVisible(true);
  }

  private mounted() {
    if (this.$route.query.camera) {
      this.onTakePhotoBtnClick();
    }
  }

  //
  // (1) image is selected from the user's computer.
  // (2) photo taken by user's camera.
  //
  public onFileInputChange(e: Event) {
    const target = e.target as HTMLInputElement;
    const file = target?.files?.item(0);
    if (file) {
      this.createModule.setStageImgUrl(URL.createObjectURL(file));
    }
  }

  @Watch('isStageImgLoaded')
  private onStageImgLoaded() {
    this.$refs.fileInput.value = '';
  }
}
