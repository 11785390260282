
import { Component, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { CreateModule } from '@/store';
import { getModule } from 'vuex-module-decorators';

// types
import { PresetListRequest } from '@/models/presets/presetListRequest';
import { PresetListItem } from '@/models/presets/presetListItem';

const createModule = getModule(CreateModule);

@Component({
  components: {},
})
export default class CreatePresetThumbnails extends LoggedInComponentBase {
  // @desktop 'None' added later = 8
  // @mob 0 loads all within v-lazy tag
  public presetsPerPage: number = this.$vuetify.breakpoint.xs ? 0 : 7;
  public currentPage: number = 1;

  public get presetCategoryId(): string {
    return createModule.presetCategoryId;
  }

  public get presetsPagination() {
    return createModule.presetsListResponse.pagination;
  }

  public get isPresetLoading() {
    return createModule.isPresetLoading;
  }

  public get selectedPresetId(): string {
    return createModule.selectedPreset.id;
  }

  public get presetsList() {
    const nonePreset: PresetListItem = {
      id: 'none',
      title: 'None',
    };
    return [nonePreset, ...createModule.presetsListResponse.data];
  }

  public get paginationLength(): number {
    if (this.presetsPagination.total <= 0) {
      return 0;
    }
    return Math.ceil(this.presetsPagination.total / this.presetsPerPage);
  }

  async mounted() {
    await this.getPresets();
  }

  async nextPage() {
    this.getPresets({ isNextPageRequest: true });
  }

  async getPresets({
    isNextPageRequest = false,
  }: {
    isNextPageRequest?: boolean;
  } = {}) {
    const paginationStart = isNextPageRequest
      ? this.presetsPerPage * (this.currentPage - 1)
      : 0;
    const params: PresetListRequest = {
      pagination: {
        limit: this.presetsPerPage,
        start: paginationStart,
      },
    };

    if (createModule.presetCategoryId === 'featured') {
      // request featured
      params.isFeatured = true;
    } else {
      // request by category id
      params.presetTemplateCategoryId = createModule.presetCategoryId;
    }

    await createModule.getPresets(params);
  }

  public onPresetClick(preset: PresetListItem) {
    createModule.setSelectedPreset(preset);
  }

  @Watch('presetCategoryId')
  public onCategoryChange() {
    this.currentPage = 1;
    this.getPresets();
  }
}
