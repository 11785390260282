
import { Component, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { getModule } from 'vuex-module-decorators';
import { CreatePostModule } from '@/store';
import { ValidationRules } from '@/components/modals/validationRules';
import { MsoPostTableHeaders, ALLSTORES } from '@/constants';
import CreatePostStoreChannelsTable from './createPostStoreChannelsTable.vue';
import { get, xor } from 'lodash';

// types
import { SocialChannel, SocialChannels } from '@/models/posts/socialChannels';
import { DataTableHeader } from 'vuetify';
import { DataTableItem } from '@/models/posts/postModal';

const createPostModule = getModule(CreatePostModule);

@Component({
  components: {
    CreatePostStoreChannelsTable,
  },
})
export default class CreatePostInfo extends LoggedInComponentBase {
  public socialChannels = SocialChannels;
  public rules: ValidationRules = new ValidationRules();
  public thumbnailUrl: string = '';

  public get currentStep(): number {
    return this.createModule.currentStep;
  }

  public get postTitle(): string {
    return createPostModule.postTitle;
  }
  public set postTitle(title: string) {
    createPostModule.setPostTitle(title);
  }
  public get postBody(): string {
    return createPostModule.postBody;
  }
  public set postBody(body: string) {
    createPostModule.setPostBody(body);
  }
  public get isAnyChannelSelected(): boolean {
    return this.selectedTableItems.length > 0;
  }
  public get isMsoView(): boolean {
    return !this.userModule.isViewingSingleStore;
  }
  public get isVideo(): boolean {
    return !!this.createModule.selectedAsset?.isVideoAsset;
  }

  //
  // store channels table and toggles
  //
  public storeChannelsTableHeaders: DataTableHeader[] = MsoPostTableHeaders;
  public selectAllValues: string[] = [];

  public onSelectAllClick(channel: SocialChannel) {
    // v-model selectAllValues does not update until next tick. Store before update to get difference
    const oldSelectAllValues = [...this.selectAllValues];
    this.$nextTick(() => {
      const diff: string = xor(this.selectAllValues, oldSelectAllValues)[0];
      const wasAdded = !oldSelectAllValues.includes(diff);
      this.toggleAllByChannel(wasAdded, channel);
    });
  }

  private get selectedTableItems(): DataTableItem[] {
    return this.storeChannelsTableItems.filter(
      (tableItem) => tableItem.facebook.value || tableItem.instagram.value
    );
  }

  public toggleAllByChannel(value: boolean, channel: SocialChannel): void {
    const property = channel.toLocaleLowerCase();
    this.storeChannelsTableItems.forEach((tableItem) => {
      // @ts-expect-error
      if (!tableItem[property].disabled) {
        // @ts-expect-error
        tableItem[property].value = value;
      }
    });

    // store selections
    createPostModule.setSelectedStoresAndChannels(this.selectedTableItems);
  }

  public onChannelToggled({
    tableItem,
    channel,
    value,
  }: {
    tableItem: DataTableItem;
    channel: SocialChannel;
    value: boolean;
  }): void {
    // store selections
    // note: this is causing vuex mutation error - not sure why, CreatePostStoreChannelsTable's v-model? syncedItems?
    createPostModule.setSelectedStoresAndChannels(this.selectedTableItems);

    if (!this.isMsoView) return;

    //
    // the following concerns when a channel is toggled we just need to know if any select-all v-chips need to be updated
    //
    const channelLower = channel.toLowerCase();

    // channel switched on
    if (value) {
      // if all enabled tableItems with this channel are selected, select-all should be on
      const channelValues = this.storeChannelsTableItems.reduce(
        (filtered, tableItem) => {
          const isDisabled = get(tableItem, `${channelLower}.disabled`);
          if (!isDisabled) {
            // @ts-expect-error
            filtered.push(get(tableItem, `${channelLower}.value`));
          }
          return filtered;
        },
        []
      );

      const allAreSelected = channelValues.every(Boolean);
      if (allAreSelected) {
        this.selectAllValues.push(channel);
      }
      return;
    }

    // channel switched off
    if (!value) {
      // if select-all for the de-selected channel is 'on' turn it off
      const index = this.selectAllValues.indexOf(channel);
      if (index !== -1) {
        this.selectAllValues.splice(index, 1);
      }
    }
  }

  public isChannelAvailable(channel: SocialChannel): boolean {
    // if a recommended templates is being used we return its available channels
    if (createPostModule.isRecommendedPost) {
      return createPostModule.recommendedPostsAvailableChannels.includes(
        channel
      );
    }
    return true;
  }

  // loops through stores and sets disabled states on channel selection
  private async checkChannelAvailability(): Promise<void> {
    const storeIds = this.storeChannelsTableItems.map((item) => item.id);
    const allStoresChannels = await this.storesModule.getAllStoresChannels(
      storeIds
    );

    this.storeChannelsTableItems.forEach((row, i) => {
      // TODO: fix a previous assumption here that this.storeChannelsTableItems[i] and allStoresChannels[i] match up.
      const storeChannels = allStoresChannels[i];
      // render stores channels as disabled if failed loading from the server
      if (storeChannels.status === 'rejected') {
        row.facebook.failedLoading = true;
        row.instagram.failedLoading = true;
      } else {
        row.facebook.disabled =
          !storeChannels.value.includes(SocialChannel.Facebook) ||
          !this.isChannelAvailable(SocialChannel.Facebook);
        row.instagram.disabled =
          !storeChannels.value.includes(SocialChannel.Instagram) ||
          !this.isChannelAvailable(SocialChannel.Instagram);
      }
    });
  }

  public get storeChannelsTableItems(): DataTableItem[] {
    // MSO
    if (this.isMsoView) {
      return this.storesModule.userStores
        .filter((store) => store.title !== ALLSTORES)
        .map((store) => ({
          id: store.id,
          name: store.title,
          region: store.state,
          facebook: { disabled: true, value: false },
          instagram: { disabled: true, value: false },
        }));
    }

    // SSO
    return [
      {
        id: this.currentStore.id,
        name: this.currentStore.title,
        region: this.currentStore.state,
        facebook: { disabled: true, value: false },
        instagram: { disabled: true, value: false },
      },
    ];
  }

  async mounted() {
    this.$root.$on('set-stage-thumbnail', (dataUrl: string) => {
      this.thumbnailUrl = dataUrl;
    });

    // user may have refreshed the page > populate user stores
    const noStores = !this.storesModule.userStores.filter(
      (store) => store.title !== ALLSTORES
    ).length;
    if (this.isMsoView && noStores) {
      await this.storesModule.getUserStores();
    }

    await this.checkChannelAvailability();
  }

  beforeDestroy() {
    this.$root.$off('set-stage-thumbnail');
  }

  @Watch('currentStep')
  private onCurrentStepChange(newVal: number) {
    if (newVal === 4 && !this.isVideo) {
      this.$root.$emit('get-stage-thumbnail');
    }
  }
}
