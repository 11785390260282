
import { Component, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { CreateModule } from '@/store';

const createModule = getModule(CreateModule);

@Component({
  components: {}
})
export default class CreateAssetImport extends LoggedInComponentBase {
  @Prop({ default: false }) isPost!: boolean;
  @Prop({ default: 0 }) currentStep!: number;

  public $refs!: Vue['$refs'] & {
    fileInput: HTMLInputElement;
    dropZone: HTMLElement;
  };

  public isDragging: boolean = false; // is drag-drop zone active - for styling

  public get isStageImgLoaded() {
    return createModule.isStageImgLoaded;
  }

  public get isShowing() {
    return (
      !createModule.isAssetLibraryVisible &&
      createModule.selectedAsset === null &&
      !this.isStageImgLoaded &&
      !this.$vuetify.breakpoint.xs
    );
  }

  public onUploadImgBtnClick(): void {
    this.$refs.fileInput.click();
  }

  public onSelectFromLibraryClick() {
    this.createModule.setIsAssetLibraryVisible(true);
  }

  //
  // image is selected from the users computer.
  //
  public onFileInputChange(e: Event) {
    const target = e.target as HTMLInputElement;
    const file = target?.files?.item(0);
    if (file) {
      createModule.setStageImgUrl(URL.createObjectURL(file));
    }
  }

  //
  // drag and drop functions
  //
  public onDropZoneDrop(e: DragEvent) {
    e.preventDefault();
    this.isDragging = false;
    const file = e.dataTransfer?.files.item(0);
    if (file) {
      if (
        ![
          'image/png',
          'image/jpeg',
          'image/gif',
          'image/bmp',
          'image/svg'
        ].includes(file?.type ?? '')
      ) {
        alert('File type not supported');
        return;
      }
      createModule.setStageImgUrl(URL.createObjectURL(file));
    }
  }

  public onDropZoneDragOver(e: DragEvent) {
    e.preventDefault();
    if (!this.isDragging) {
      this.isDragging = true;
    }
  }

  public onDropZoneDragLeave(e: { target: HTMLElement }) {
    if (e.target.classList.contains('drop-zone')) {
      this.isDragging = false;
    }
  }

  @Watch('isStageImgLoaded')
  private onStageImgLoaded() {
    this.$refs.fileInput.value = '';
  }
}
