// TODO: types need work

import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { Component } from 'vue-property-decorator';
import Konva from 'konva';
import { Shape, ShapeConfig } from 'konva/cmj/Shape';
import { debounce } from 'lodash';

@Component
export class MixinKonvaSnapDrag extends LoggedInComponentBase {
  private SNAP_OFFSET = 10;
  public stage!: Konva.Stage;
  public image!: Konva.Image;
  public layer!: Konva.Layer;
  public targetRect!: Konva.Rect;
  public transformer!: Konva.Transformer;
  public debouncedSetDirty = debounce(this.setDirty, 300);

  public MixinKonvaSnapDrag(
    _stage: Konva.Stage,
    _image: Konva.Image,
    _targetRect: Konva.Rect,
    _transformer: Konva.Transformer
  ) {
    this.stage = _stage;
    this.image = _image;
    this.targetRect = _targetRect;
    this.transformer = _transformer;

    this.layer = new Konva.Layer();
    this.stage.add(this.layer);

    this.image.on('dragend', this.onImageDragEnd);
    this.image.on('dragmove', this.onImageDragMove);
    this.transformer.anchorDragBoundFunc(this.anchorDragBoundFunc);
  }

  private onImageDragMove(e: any) {
    // clear previous guides
    ((this.layer.find('.guid-line') as unknown) as Konva.Line[]).forEach(l =>
      l.destroy()
    );

    // find possible snapping lines DONE
    const lineGuideStops = this.getLineGuideStops();

    // find snapping points of current object DONE
    const itemBounds = this.getObjectSnappingEdges(e.target as Shape);

    // now find where can we snap current object
    const guides = this.getGuides(lineGuideStops, itemBounds);

    // no results
    if (!guides.length) {
      return;
    }

    this.drawGuides(guides);

    const absPos = e.target.absolutePosition();
    // now force object position
    guides.forEach(lg => {
      switch (lg.snap) {
        case 'start': {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            case 'H': {
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
          }
          break;
        }
        case 'center': {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            case 'H': {
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
          }
          break;
        }
        case 'end': {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            case 'H': {
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
          }
          break;
        }
      }
    });
    e.target.absolutePosition(absPos);
  }

  private onImageDragEnd() {
    ((this.layer.find('.guid-line') as any) as Konva.Line[]).forEach(l =>
      l.destroy()
    );

    this.debouncedSetDirty();
  }

  // were can we snap our objects?
  private getLineGuideStops() {
    // snap to targetRect borders and center of the stage
    const vertical: any[] = [
      this.targetRect.x(),
      this.stage.width() / 2,
      this.targetRect.x() + this.targetRect.width()
    ];
    const horizontal: any[] = [
      this.targetRect.y(),
      this.stage.height() / 2,
      this.targetRect.y() + this.targetRect.height()
    ];

    return {
      vertical: vertical, // prob remove flat
      horizontal: horizontal
    };
  }

  // what points of the object will trigger to snapping?
  // it can be just center of the object
  // but we will enable all edges and center
  private getObjectSnappingEdges(node: Shape<ShapeConfig>) {
    const box = node.getClientRect();
    const absPos = node.absolutePosition();

    return {
      vertical: [
        {
          guide: Math.round(box.x),
          offset: Math.round(absPos.x - box.x),
          snap: 'start'
        },
        {
          guide: Math.round(box.x + box.width / 2),
          offset: Math.round(absPos.x - box.x - box.width / 2),
          snap: 'center'
        },
        {
          guide: Math.round(box.x + box.width),
          offset: Math.round(absPos.x - box.x - box.width),
          snap: 'end'
        }
      ],
      horizontal: [
        {
          guide: Math.round(box.y),
          offset: Math.round(absPos.y - box.y),
          snap: 'start'
        },
        {
          guide: Math.round(box.y + box.height / 2),
          offset: Math.round(absPos.y - box.y - box.height / 2),
          snap: 'center'
        },
        {
          guide: Math.round(box.y + box.height),
          offset: Math.round(absPos.y - box.y - box.height),
          snap: 'end'
        }
      ]
    };
  }

  // find all snapping possibilities
  private getGuides(lineGuideStops: any, itemBounds: any) {
    const resultV: any[] = [];
    const resultH: any[] = [];

    lineGuideStops.vertical.forEach((lineGuide: any) => {
      itemBounds.vertical.forEach((itemBound: any) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        // if the distance between guild line and object snap point is close we can consider this for snapping
        if (diff < this.SNAP_OFFSET) {
          resultV.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset
          });
        }
      });
    });

    lineGuideStops.horizontal.forEach((lineGuide: any) => {
      itemBounds.horizontal.forEach((itemBound: any) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < this.SNAP_OFFSET) {
          resultH.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset
          });
        }
      });
    });

    // find snaps within tolerance
    const vertGuides = resultV.map(guide => {
      if (guide.diff < this.SNAP_OFFSET) {
        guide.orientation = 'V';
        return guide;
      }
    });
    const horizGuides = resultH.map(guide => {
      if (guide.diff < this.SNAP_OFFSET) {
        guide.orientation = 'H';
        return guide;
      }
    });

    return [...vertGuides, ...horizGuides];
  }

  private drawGuides(guides: any) {
    guides.forEach((guide: any) => {
      if (guide.orientation === 'H') {
        const line = new Konva.Line({
          points: [-6000, 0, 6000, 0],
          stroke: this.$vuetify.theme.themes.light.primary?.toString(),
          strokeWidth: 1,
          name: 'guid-line',
          dash: [7, 9],
          opacity: 0.75
        });
        this.layer.add(line);
        line.absolutePosition({
          x: 0,
          y: guide.lineGuide
        });
      } else if (guide.orientation === 'V') {
        const line = new Konva.Line({
          points: [0, -6000, 0, 6000],
          stroke: this.$vuetify.theme.themes.light.primary?.toString(),
          strokeWidth: 1,
          name: 'guid-line',
          dash: [7, 9],
          opacity: 0.75
        });
        this.layer.add(line);
        line.absolutePosition({
          x: guide.lineGuide,
          y: 0
        });
      }
    });
  }

  private anchorDragBoundFunc(oldPos: any, newPos: any, event: any) {
    // oldPos - is old absolute position of the anchor
    // newPos - is a new (possible) absolute position of the anchor based on pointer position
    // it is possible that anchor will have a different absolute position after this function
    // because every anchor has its own limits on position, based on resizing logic

    this.debouncedSetDirty();

    if (this.transformer.getActiveAnchor() === 'rotater') {
      // do nothing
      return newPos;
    }

    // snap to targetRect

    const topBound = this.targetRect.y();
    const topBoundMin = topBound - this.SNAP_OFFSET;
    const topBoundMax = topBound + this.SNAP_OFFSET;

    const rightBound = this.targetRect.x() + this.targetRect.width();
    const rightBoundMin = rightBound - this.SNAP_OFFSET;
    const rightBoundMax = rightBound + this.SNAP_OFFSET;

    const bottomBound = this.targetRect.y() + this.targetRect.height();
    const bottomBoundMin = bottomBound - this.SNAP_OFFSET;
    const bottomBoundMax = bottomBound + this.SNAP_OFFSET;

    const leftBound = this.targetRect.x();
    const leftBoundMin = leftBound - this.SNAP_OFFSET;
    const leftBoundMax = leftBound + this.SNAP_OFFSET;

    if (newPos.x >= rightBoundMin && newPos.x <= rightBoundMax) {
      return {
        x: rightBound,
        y: oldPos.y
      };
    }

    if (newPos.x >= leftBoundMin && newPos.x <= leftBoundMax) {
      return {
        x: leftBound,
        y: oldPos.y
      };
    }

    if (newPos.y >= topBoundMin && newPos.y <= topBoundMax) {
      return {
        x: oldPos.x,
        y: topBound
      };
    }

    if (newPos.y >= bottomBoundMin && newPos.y <= bottomBoundMax) {
      return {
        x: oldPos.x,
        y: bottomBound
      };
    }
    return newPos;
  }

  private setDirty() {
    this.$root.$emit('stage-is-dirty-by-move');
  }
}
