
import { Component, Prop } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';

@Component({
  components: {}
})
export default class CreateNavigation extends LoggedInComponentBase {
  @Prop() currentStep!: number;
  @Prop() isNextDisabled!: boolean;
  @Prop() nextBtnText!: string;
  @Prop({ default: false }) readonly isSubmitting!: boolean;

  public get isBackVisible(): boolean {
    if (
      this.createModule.selectedAsset?.isVideoAsset &&
      this.currentStep === 2 &&
      !this.$vuetify.breakpoint.xs
    ) {
      return false;
    }

    return this.currentStep > 1;
  }
}
