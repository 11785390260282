
import { Component, Prop, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';

interface Steps {
  [key: string]: string[];
}

const steps: Steps = {
  post: ['Asset and styling', 'Post information', 'Schedule'],
  asset: ['Asset and styling', 'Asset information']
};

@Component({
  components: {}
})
export default class CreateStepperHeader extends LoggedInComponentBase {
  @Prop({ default: false }) isPost!: boolean;
  @Prop({ default: 1 }) currentStep!: number;

  public getStepHeader(index: number): string {
    return steps[this.isPost ? 'post' : 'asset'][index];
  }

  @Watch('currentStep')
  public async onStepChange() {
    this.$emit('step-header-change', this.getStepHeader(this.currentStep - 1));
  }
}
